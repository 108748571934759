import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const GDPRPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>GDPR</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span>Last Updated: September 20, 2023</span>
            </p>
            <p>
              <h2>1. Introduction</h2>
            </p>
            <p>
              <span>
                Welcome to PJK MARKETING LTD ("we," "us," or "our"). At PJK
                MARKETING LTD, we are committed to protecting your personal data
                and ensuring that your privacy is maintained. This Privacy
                Notice explains how we collect, use, disclose, and safeguard
                your personal information in accordance with the General Data
                Protection Regulation (GDPR) and other applicable data
                protection laws.
              </span>
            </p>
            <p>
              <h2>2. Data Controller</h2>
            </p>
            <p>
              <span>
                PJK MARKETING LTD is the data controller for the processing of
                your personal data. Our contact information is as follows:
              </span>
            </p>
            <ul>
              <li>Company Name: PJK MARKETING LTD</li>
              <li>
                Registered Address: Hope Cottage Draycott, Claverley,
                Wolverhampton, Shropshire, England, WV5 7EA
              </li>
              <li>Email: info@ai-productreviews.com</li>
            </ul>
            <p>
              <h2>3. Information We Collect</h2>
            </p>
            <p>
              <span>
                We may collect and process the following types of personal data:
              </span>
            </p>
            <ul>
              <li>
                Contact Information: Name, email address, and phone number.
              </li>
              <li>
                Website Usage Data: Information about how you use our website,
                including your IP address, browser type, pages visited, and
                other technical information.
              </li>
              <li>
                Communication Data: Information you provide when contacting us,
                such as emails, letters, or messages.
              </li>
              <li>
                Consent Records: Records of any consent you have given us.
              </li>
            </ul>
            <p>
              <h2>4. How We Use Your Data</h2>
            </p>
            <p>
              <span>We use your personal data for the following purposes:</span>
            </p>
            <ul>
              <li>To provide and maintain our services.</li>
              <li>To communicate with you and respond to your inquiries.</li>
              <li>To improve our website and services.</li>
              <li>To comply with legal obligations.</li>
            </ul>
            <p>
              <h2>5. Data Sharing</h2>
            </p>
            <p>
              <span>
                We may share your personal data with third parties, including
                service providers, legal authorities, or where required by law.
              </span>
            </p>
            <p>
              <h2>6. Your Rights</h2>
            </p>
            <p>
              <span>
                Under GDPR, you have certain rights regarding your personal
                data, including the right to access, correct, delete, or
                restrict its processing. You also have the right to withdraw
                your consent at any time when applicable.
              </span>
            </p>
            <p>
              <h2>7. Data Security</h2>
            </p>
            <p>
              <span>
                We take data security seriously and implement appropriate
                technical and organizational measures to protect your personal
                data.
              </span>
            </p>
            <p>
              <h2>8. Contact Us</h2>
            </p>
            <p>
              <span>
                If you have any questions or concerns about our GDPR compliance
                or how we handle your personal data, please contact us:
              </span>
            </p>
            <p>PJK MARKETING LTD</p>
            <p>Hope Cottage Draycott, Claverley,</p>
            <p>Wolverhampton, Shropshire,</p>
            <p>England, WV5 7EA</p>
            <p>Email: info@ai-productreviews.com</p>
            <p>
              <h2>9. Changes to This Privacy Notice</h2>
            </p>
            <p>
              <span>
                We may update this Privacy Notice from time to time to reflect
                changes in our data processing practices or for other
                operational, legal, or regulatory reasons. Please check this
                page periodically for updates.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default GDPRPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"GDPR | AI Product Reviews"}
      description={
        "Our dedication to ethical AI practices. Discover our modern slave statement and responsible AI commitment."
      }
      pathname={location.pathname}
    />
  );
};
